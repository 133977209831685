import updateFieldSlice from "./../slices/updateFieldSlice";
import { combineReducers } from "@reduxjs/toolkit";
import finchatReducer from "../slices/finchatSlice";
import themeReducer from "../slices/themeSlice";
import { authReducer } from "./authReducer";
import notificationReducer from "../slices/notificationSlice";
import pastChatsSlice from "../slices/pastChatsSlice";
import pastSessionSlice from "../slices/pastSessionSlice";
import sharedSessionSlice from "./../slices/sharedSessionSlice";
import promptsSlice from "../slices/promptsSlice";
import changeTitleSlice from "../slices/changeTitleSlice";
import deleteChatSlice from "../slices/deleteChatSlice";
import activePlanSlice from "../slices/activePlanSlice";
import activeUserSlice from "../slices/userSlice";
import fetchAuthToken from "../slices/fetchAuthToken";
import allPlanSlice from "../slices/allPlanSlice";
import deleteAllSessionsSlice from "../slices/deleteAllSessionsSlice";
import websocketReducer from "../slices/websocketSlice";
import changeSubscriptionSlice from "../slices/changeSubscriptionSlice";
import deleteUserSlice from "../slices/deleteUserSlice";
import validateUserSlice from "../slices/validateUserSlice";
import IntroPromoSlice from "../slices/IntroPromoSlice";
import userPreferencesSlice from "../slices/userPreferencesSlice";
import changeLogsSlice from "../slices/changeLogsSlice";
import recommendedPromptsSlice from "../slices/recommendedPromptsSlice";
import watchlistSlice from "../slices/watchlistSlice";
import tradierAccountSlice from "../slices/tradierAccountSlice";
import tradeIdeasSlice from "../slices/tradeIdeasSlice";
import tradeExecutionSlice from "../slices/tradeExecutionSlice";
import tradeOrdersSlice from "../slices/tradeOrdersSlice";
import dxFeedSlice from "../slices/dxFeedSlice";

const rootReducer = combineReducers({
  finchat: finchatReducer,
  theme: themeReducer,
  auth: authReducer,
  notification: notificationReducer,
  chat: pastChatsSlice,
  session: pastSessionSlice,
  sharedSession: sharedSessionSlice,
  prompts: promptsSlice,
  changeTitle: changeTitleSlice,
  deleteChat: deleteChatSlice,
  plan: activePlanSlice,
  user: activeUserSlice,
  token: fetchAuthToken,
  plans: allPlanSlice,
  deleteAllSessions: deleteAllSessionsSlice,
  updateFields: updateFieldSlice,
  websocket: websocketReducer,
  checkout: changeSubscriptionSlice,
  deleteUser: deleteUserSlice,
  validUser: validateUserSlice,
  introPromo: IntroPromoSlice,
  userPreferences: userPreferencesSlice,
  changeLogs: changeLogsSlice,
  recommendedPrompts: recommendedPromptsSlice,
  watchlist: watchlistSlice,
  tradierAccount: tradierAccountSlice,
  tradeIdeas: tradeIdeasSlice,
  tradeExecution: tradeExecutionSlice,
  tradeOrders: tradeOrdersSlice,
  dxFeed: dxFeedSlice,
});

export default rootReducer;
