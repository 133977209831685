import axios from "axios";

export const fetchCompanyData = async (tickers) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_SPL_URL}/last-close-price/`,
    { tickers },
    {
      withCredentials: true,
    },
  );

  if (!data || Object.keys(data).length === 0) {
    return;
  }
  return data;
};
