import { useCallback, useEffect, useMemo, useState, memo, useRef } from "react";
import { ReactComponent as SignalStroke } from "../../assets/icons/SignalStroke.svg";
import { ReactComponent as CogIcon } from "../../assets/icons/CogIcon.svg";
import { ReactComponent as SearchStroke } from "../../assets/icons/SearchStroke.svg";
import {
  Alert,
  AlertTitle,
  Badge,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Skeleton,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { MODE } from "./constants";
import {
  setExpandedTicker,
  getTickersInWatchlist,
  updateTickerOrder,
  searchTickerResults,
  deleteTickers,
  resetRemoveList,
  updateTickerSequence,
  resetReorderList,
  addTicker,
  setWatchlistDrawerState,
  resetError,
  resetSearchResults,
} from "../../redux/slices/watchlistSlice";
import { showNotification } from "../../redux/slices/notificationSlice";
import { debounce, isEqual } from "lodash";
import { CrossIcon } from "../Plans/icons/GPTIcons/CrossIcon";
import { useIncrementTrigger } from "./hooks";
import TickerCard from "./Ticker";
import { connectToDXFeed, disconnectFromSocket } from "../../redux/slices/dxFeedSlice";
import { fetchCompanyData } from "../api/fetchCompanyData";

const Watchlist = memo(
  ({
    isOpen,
    handlePrompt,
  }: {
    isOpen: boolean;
    handlePrompt: (msg: string) => void;
  }) => {
    const [mode, setMode] = useState(MODE.VIEW);
    const [searchText, setSearchText] = useState("");
    const [hoveredTickerId, setHoveredTickerId] = useState(null);
    const {
      tickers,
      searchResults,
      expandedTicker,
      isLoading,
      removedIds,
      reorderIds,
      isMutating,
      isHighlightBadge,
      error,
      isAdding,
    } = useSelector((state: { watchlist }) => state.watchlist);

    const user = useSelector((state: { auth }) => state?.auth?.currentUser);
    const tickersOrder
    = reorderIds.length > 0 && tickers.length > 0
      ? reorderIds
      : tickers.map(({ ticker }) => ticker) || [];

    const watchlistTickers = useMemo(
      () => tickers.map(ticker => ticker.ticker) || [],
      [tickers],
    );

    const searchResultsTickers = useMemo(
      () => searchResults?.map(ticker => ticker.ticker) || [],
      [searchResults],
    );
    const { updateBadgeCount } = useIncrementTrigger();

    const dispatch = useDispatch<AppDispatch>();

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [visibleTickers, setVisibleTickers] = useState<Set<string>>(new Set());
    const [tickerData, setTickerData] = useState({});
    const observerRef = useRef(null);
    useEffect(() => {
      if (error) {
        setShowErrorMessage(true);

        // Clear the notification after 3 seconds
        const timer = setTimeout(() => {
          setShowErrorMessage(false);
          dispatch(resetError());
        }, 3000);

        return () => clearTimeout(timer);
      }
      else {
        setShowErrorMessage(false);
      }
    }, [error, dispatch]);

    const isSearchMode = mode === MODE.SEARCH;

    useEffect(() => {
      if (isOpen) {
        setMode(MODE.VIEW);
      }
      return () => setMode(MODE.VIEW);
    }, [isOpen]);

    const allTickers = useMemo(
      () => Array.from(new Set([...watchlistTickers, ...searchResultsTickers])),
      [watchlistTickers, searchResultsTickers],
    );

    const handleListUpdate = useCallback(async () => {
      const notifyError = () => {
        dispatch(
          showNotification({
            message: "Fail to save watchlist modification",
            severity: "error",
            horizontal: "center",
          }),
        );
      };

      try {
        if (removedIds.length > 0) {
          await dispatch(
            deleteTickers({ ids: removedIds, userId: user.userId }),
          ).unwrap();
          dispatch(resetRemoveList());
        }
        if (reorderIds.length > 0) {
          await dispatch(
            updateTickerSequence({
              payload: reorderIds.map((id, idx) => ({
                ticker: id,
                seq: idx + 1,
              })),
              userId: user.userId,
            }),
          ).unwrap();
          dispatch(resetReorderList());
        }

        if (removedIds.length > 0 || reorderIds.length > 0) {
          await backToViewMode();
        }
      }
      catch (_e) {
        notifyError();
      }
    }, [removedIds, reorderIds, user?.userId, dispatch]);

    const backToViewMode = async () => {
      setMode(MODE.VIEW);
      setSearchText("");
      await dispatch(getTickersInWatchlist({ userId: user?.userId }));
    };

    useEffect(() => {
      if (mode !== MODE.LIST_MANAGEMENT) {
        setTickerData({});
      }
      const fetchData = async () => {
        if (mode !== MODE.SEARCH) {
          try {
            dispatch(resetSearchResults());
          }
          catch (error) {
            console.error("Error fetching tickers in watchlist:", error);
          }
        }
      };

      fetchData();
    }, [mode]);

    const debouncedDispatch = useCallback(
      debounce((text) => {
        dispatch(searchTickerResults(text));
      }, 500),
      [dispatch],
    );
    useEffect(() => {
      if (!searchText) {
        dispatch(resetSearchResults());
      }
      if (searchText && isSearchMode) {
        debouncedDispatch(searchText);
      }
    }, [searchText, isSearchMode, debouncedDispatch]);

    const handleDragStart = useCallback(
      (e, id) => {
        if (mode !== MODE.LIST_MANAGEMENT) {
          e.preventDefault();
          return;
        }
        e.dataTransfer.setData("text/plain", id);
      },
      [mode],
    );

    const handleDragOver = useCallback((e, id) => {
      e.preventDefault();
      setHoveredTickerId(id);
    }, []);

    const handleDragLeave = useCallback(() => {
      setHoveredTickerId(null);
    }, []);

    const handleDrop = (e, dropId) => {
      const dragId = e.dataTransfer.getData("text");
      const dragIndex = tickersOrder.findIndex(item => item === dragId);
      const dropIndex = tickersOrder.findIndex(item => item === dropId);
      const reorderedItems = [...tickersOrder];
      const [movedItem] = reorderedItems.splice(dragIndex, 1);
      reorderedItems.splice(dropIndex, 0, movedItem);
      setHoveredTickerId(null);
      if (
        isEqual(
          reorderedItems,
          tickers.map(({ ticker }) => ticker),
        )
      ) {
        dispatch(resetReorderList());
      }
      else {
        dispatch(updateTickerOrder(reorderedItems));
      }
    };

    const filteredTickers = !isSearchMode
      ? (tickers.filter(({ ticker }) => !removedIds.includes(ticker)) || [])
      : (searchResults || []);

    const displayedTickers = !isSearchMode
      ? filteredTickers.sort((a, b) => tickersOrder.indexOf(a.ticker) - tickersOrder.indexOf(b.ticker))
      : filteredTickers;

    const onAddTicker = useCallback(async (tickerName) => {
      try {
        await dispatch(addTicker({ userId: user?.userId, ticker: tickerName })).unwrap();
        updateBadgeCount();
      }
      catch (error) {
        console.error("Caught error in component:", error);
        throw error;
      }
    }, [dispatch, user]);

    const textFieldRef = useRef(null);

    const handleAddTickersClick = () => {
      setMode(MODE.SEARCH);
      if (textFieldRef.current) {
        textFieldRef.current.focus();
        textFieldRef.current.querySelector("input").focus();
      }
    };

    // SUBSCRIBE TO DX FEED SOCKET
    useEffect(() => {
      if (isOpen) {
        dispatch(connectToDXFeed(allTickers));
      }
    }, [dispatch, allTickers, isOpen]);

    // watchlist cleanup hook
    useEffect(() => {
      return () => {
        dispatch(disconnectFromSocket());
      };
    }, [dispatch, isOpen]);

    const getPrevCloseAndName = async () => {
      const tickersToFetch = Array.from(visibleTickers).filter(
        ticker => !tickerData[ticker],
      );
      if (tickersToFetch.length === 0) return;
      try {
        const data = await fetchCompanyData(tickersToFetch);

        if (Array.isArray(data)) {
          const dataMap = data.reduce((acc, item) => {
            acc[item.ticker] = {
              name: item.name,
              prev_close_price: item.prev_close_price,
            };
            return acc;
          }, {});

          setTickerData(prev => ({ ...prev, ...dataMap }));
        }
      }
      catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    const debouncedFetch = debounce(getPrevCloseAndName, 300);
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          setVisibleTickers((prevVisibleTickers) => {
            const updatedVisibleTickers = new Set(prevVisibleTickers);

            entries.forEach((entry) => {
              const target = entry.target as HTMLElement;
              const ticker = target.dataset.ticker;

              if (entry.isIntersecting) {
                updatedVisibleTickers.add(ticker);
              }
              else {
                updatedVisibleTickers.delete(ticker);
              }
            });

            return updatedVisibleTickers;
          });
        },
        { threshold: 0.1 },
      );

      observerRef.current = observer;

      return () => {
        if (observerRef.current) {
          observerRef.current.disconnect();
        }
      };
    }, []);

    useEffect(() => {
      debouncedFetch();
      return () => debouncedFetch.cancel();
    }, [visibleTickers]);

    return (
      <div
        className={`dark:bg-black text-mui-black-87 dark:text-white z-10 w-full md:w-[360px] shadow-custom watchlist ${
          isOpen ? "" : "hidden"
        }`}
      >
        <div className=" flex flex-col p-4 h-full">
          <header className="sticky top-0 z-20 flex flex-col gap-y-4">
            <section className="flex justify-between items-center">
              <div className="flex gap-x-2 dark:text-white items-center">
                <SignalStroke className="fill-current" />
                <p className="body1 pe-3">Watchlist</p>
                {tickers.length > 0 && !isAdding && !isLoading && (
                  <Badge
                    variant="standard"
                    badgeContent={
                      mode === MODE.LIST_MANAGEMENT
                        ? tickers.length - removedIds.length
                        : tickers.length
                    }
                    className={
                      // whenever under edit mode and there is add/delete
                      (mode !== MODE.VIEW && removedIds.length > 0)
                      || isHighlightBadge
                        ? "blue-badge"
                        : "default-badge"
                    }
                  />
                )}
                {isAdding && (
                  <CircularProgress disableShrink color="inherit" className="text-tgpt-secondary-main" size={12}></CircularProgress>
                )}
              </div>
              <div className="flex gap-x-1 items-center">
                {mode !== MODE.LIST_MANAGEMENT
                  ? (
                      <IconButton
                        size="small"
                        className="cross-icon"
                        disabled={tickers.length === 0}
                        onClick={() => setMode(MODE.LIST_MANAGEMENT)}
                      >
                        <CogIcon className="fill-current m-1" />
                      </IconButton>
                    )
                  : removedIds.length + reorderIds.length > 0
                    ? (
                        <Button
                          variant="text"
                          color="primary"
                          size="medium"
                          className="button-normalcase"
                          onClick={handleListUpdate}
                          disabled={isMutating}
                        >
                          Save
                        </Button>
                      )
                    : (
                        <Button
                          variant="text"
                          size="medium"
                          color="primary"
                          onClick={backToViewMode}
                          className="button-normalcase"
                        >
                          Done
                        </Button>
                      )}
                <div className="block sm:hidden">
                  <IconButton
                    size="small"
                    className="cross-icon"
                    onClick={() => dispatch(setWatchlistDrawerState(false))}
                  >
                    <CrossIcon className="fill-current m-1" />
                  </IconButton>
                </div>
              </div>
            </section>
            {showErrorMessage && (
              <section>
                <Alert severity="error">
                  <AlertTitle>Watchlist Limit Reached</AlertTitle>
                  Manage your watchlists to add more.
                </Alert>
              </section>
            )}
            {!showErrorMessage && mode !== MODE.LIST_MANAGEMENT && (
              <section className={isSearchMode ? "flex justify-between" : ""}>
                <div className={isSearchMode ? "w-1/2 sm:w-[180px]" : ""}>
                  <TextField
                    placeholder="Search Tickers..."
                    ref={textFieldRef}
                    variant="outlined"
                    size="small"
                    className="rounded-textfield outlined-input labelText"
                    fullWidth
                    value={searchText}
                    onFocus={() => setMode(MODE.SEARCH)}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      setMode(MODE.SEARCH);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchStroke className="dark:text-white" />
                          {" "}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {isSearchMode && (
                  <Button
                    variant="text"
                    color="primary"
                    size="medium"
                    className="button-normalcase"
                    onClick={() => {
                      backToViewMode();
                    }}
                  >
                    Back to watchlist
                  </Button>
                )}
              </section>
            )}
            <div className="flex w-full border-b border-mui-black-12 dark:border-mui-white-12">
              <div
                className={`caption flex w-full justify-between py-1 ${
                  mode === MODE.LIST_MANAGEMENT
                    ? "px-6"
                    : mode === MODE.SEARCH
                      ? "ps-8 pe-2"
                      : "px-2"
                } text-mui-black-60 dark:text-mui-white-70`}
              >
                <p>Symbol</p>
                <p className={` ${mode !== MODE.SEARCH ? "pe-4" : "ps-2"}`}>Sparkline</p>
                <p>Price</p>
              </div>
            </div>
          </header>
          {!isLoading
          && ((!tickers.length && !isSearchMode) || (isSearchMode && searchText === "")) && (
            <section className="flex-grow flex items-center justify-center">
              <div className="flex text-center gap-y-6">
                <div className="flex flex-col items-center text-center gap-y-6">
                  <SignalStroke className="h-20 w-20 text-mui-black-56 dark:text-mui-white-56" />
                  <p className="body2 text-mui-black-60 dark:text-mui-white-70">
                    {isSearchMode
                      ? "Type to begin searching for tickers or click 'Back to watchlist' to return to your watchlist."
                      : "Your watchlist is currently empty. Start adding tickers to stay informed."}
                  </p>
                  {!isSearchMode && (
                    <Button
                      variant="outlined"
                      className="button-outlined"
                      color="inherit"
                      size="medium"
                      onClick={() => handleAddTickersClick()}
                    >
                      Add Tickers
                    </Button>
                  )}
                </div>
              </div>
            </section>
          )}

          {((!isSearchMode && tickers.length > 0)
          || (isSearchMode && searchText !== "")) && (
            <section className="flex-grow overflow-y-auto overflow-x-hidden scroll-container">
              <div className="flex flex-col">
                {isLoading || isMutating || (isSearchMode && isLoading)
                  ? Array.from({ length: 5 }).map((_, index) => (
                    <Skeleton
                      key={index}
                      variant="text"
                      sx={{ fontSize: "3rem", width: "100%" }}
                      animation="wave"
                      className=" dark:bg-white dark:opacity-12"
                    />
                  ))
                  : Array.isArray(displayedTickers) && displayedTickers.length > 0
                    ? displayedTickers.map((ticker, index) => (
                      <div
                        key={ticker.ticker}
                        data-ticker={ticker.ticker}
                        ref={(el) => {
                          if (el && observerRef.current instanceof IntersectionObserver) {
                            observerRef.current.observe(el);
                          }
                        }}
                        className={`flex gap-x-2 ${
                          index !== 0 ? "border-t border-mui-black-12 dark:border-mui-white-12" : ""
                        } ${ticker.name ? "cursor-pointer" : ""}`}
                        onClick={() => dispatch(setExpandedTicker(ticker.ticker))}
                      >
                        <TickerCard
                          ticker={ticker}
                          isSelected={ticker?.ticker === expandedTicker}
                          mode={mode}
                          tickerData={tickerData[ticker.ticker] || null}
                          onDragStart={handleDragStart}
                          onDragOver={handleDragOver}
                          onDragLeave={handleDragLeave}
                          onDrop={handleDrop}
                          handlePrompt={handlePrompt}
                          onAddTicker={onAddTicker}
                          className={`${
                            ticker.ticker === hoveredTickerId
                              ? "border border-mui-primary-blue-light"
                              : ""
                          }`}
                        />
                      </div>
                    ))
                    : null}
              </div>
            </section>
          )}
        </div>
      </div>
    );
  },
);

Watchlist.displayName = "Watchlist";
export default Watchlist;
