import { IconButton, Modal } from "@mui/material";
import { CrossIcon } from "./Plans/icons/GPTIcons/CrossIcon";

interface ImageModalType {
  open: boolean;
  imageSrc: string;
  onClose: () => void;
}

const ImageModal: React.FC<ImageModalType> = ({
  open = false,
  imageSrc,
  onClose,
}) => {
  return (
    <Modal className="flex justify-center items-center" open={open} onClose={onClose}>
      <>
        <IconButton
          size="small"
          onClick={onClose}
          className="cross-icon"
          sx={{ position: "absolute", top: 16, right: 16 }}
        >
          <CrossIcon className="fill-current" />
        </IconButton>
        <div className="shadow rounded-lg bg-white dark:bg-background/paper-elevation-16 overflow-hidden">
          <img src={imageSrc} className="object-cover max-w-modal-mobile sm:max-w-modal-sm md:max-w-modal-md lg:max-w-modal-lg xl:max-w-modal-xl max-h-[756px]" alt="uploaded image" />
        </div>
      </>
    </Modal>
  );
};

export default ImageModal;
