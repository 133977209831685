import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";

const SparklineChart = ({ series, priceChange }: { series: number[]; priceChange: number | null }) => {
  const [isDecreasing, setIsDecreasing] = useState<boolean | null>(null);

  useEffect(() => {
    if (priceChange !== null) {
      setIsDecreasing(priceChange < 0);
    }
  }, [priceChange]);

  if (priceChange === null) {
    return null;
  }

  const strokeColor = isDecreasing ? "#EF5350" : "#4CAF50";
  const fillColors = isDecreasing
    ? ["#F4433640", "#F4433601"]
    : ["#3EB489", "#3EB48900"];

  const options: Highcharts.Options = {
    chart: {
      type: "area",
      height: 44,
      width: 90,
      spacing: [0, 0, 0, 0],
    },
    accessibility: {
      enabled: false,
    },
    stockTools: {
      gui: {
        enabled: false,
      },
    },
    title: {
      text: null,
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      area: {
        marker: {
          enabled: false,
        },
        fillOpacity: 0.1,
        lineWidth: 1.5,
        lineColor: strokeColor,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, fillColors[0]],
            [1, fillColors[1]],
          ],
        },
        states: {
          hover: {
            enabled: false,
          },
        },
        threshold: null,
        animation: {
          duration: 1200,
          easing: "cubic-bezier(0.62, 0.1, 0.18, 1)",
        },
      },
    },
    series: [
      {
        type: "area",
        name: "tickersparkline",
        data: series,
        animation: {
          duration: 1200,
          easing: "cubic-bezier(0.62, 0.1, 0.18, 1)",
        },
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{ style: { overflow: "hidden" } }}
    />
  );
};

export default SparklineChart;
