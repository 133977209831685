import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import {
  Button,
  useTheme,
  useMediaQuery,
  Tooltip,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import "./tradegpt.scss";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  finchatMessageCancel,
  finchatMessageLoad,
} from "../redux/slices/finchatSlice";
import {
  PostMessagesFunction,
  FinchatMessagePayload,
  Message,
  MessageAttachments,
  RootState,
  sendMessageFunction,
} from "../assets/interfaces/interfaces";
import ChatWindow from "./ChatWindow";
import MessageInput from "./MessageInput";
import Sidebar from "./Sidebar/Sidebar";
import MenuIcon from "@mui/icons-material/Menu";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { ReactComponent as ShareStroked } from "../assets/icons/ShareStroked.svg";
import { transformArray } from "../helpers";
import LimitModal from "./LimitModal";
import classNames from "classnames";
import { getAuth } from "firebase/auth";
import { AppDispatch } from "../redux/store";
import { fetchPrompts } from "../redux/slices/promptsSlice";
import useEnhancedNavigate from "./Session/helpers";
import Dashboard from "./Dashboard/Dashboard";
import { addMessageOptimistically } from "../redux/slices/pastChatsSlice";
import { fetchAllPlan } from "../redux/slices/allPlanSlice";
import ChangeLogsPage from "./ChangeLogsPage";
import { clearPromptSelected } from "../redux/slices/recommendedPromptsSlice";
import {
  setWatchlistDrawerState,
  getTickersInWatchlist,
} from "../redux/slices/watchlistSlice";
import PerformanceDashboard from "./PerformanceDashboard/PerformanceDashboard";
import { logClickEvent } from "../analytics";
import { ReactComponent as SignalStroke } from "../assets/icons/SignalStroke.svg";
import Watchlist from "./Watchlist";
import Modal from "./Watchlist/Modal";

const makeUserMessage = (text: string, attachments?: MessageAttachments) => {
  return {
    isBot: false,
    output: text,
    attachments,
  };
};

// Main App component
const FinchatGPT = ({ showDashboard = false, showChangeLogs = false, showPerformanceDashboard = false }) => {
  const sidebarCarrotRef = useRef<HTMLDivElement>(null);
  const user = useSelector((state: { auth }) => state.auth.currentUser);
  const isDarkMode = useSelector((state: { theme }) => state.theme.darkMode);
  const plan = useSelector((state: { plan }) => state?.plan?.plan);
  const tokenStatus = useSelector((state: { token }) => state.token.status);
  const { session_id, prompt } = useParams();
  const sessionIdSafe = session_id || uuidv4(); // Default to a new UUID if sessionId is undefined
  const finchatMessage = useSelector(
    (state: RootState) => state?.finchat?.finchatMessages[session_id],
  );
  const loading = useSelector((state: RootState) => state?.finchat?.loading);
  const { status: allStatus } = useSelector((state: { plans }) => state.plans);
  const { status: planStatus } = useSelector((state: { plan }) => state.plan);
  const { pastMessages } = useSelector((state: RootState) => state.session);
  const isChatPage = !showDashboard && !showChangeLogs;

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useEnhancedNavigate();

  const auth = getAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const prompts = useSelector((state: RootState) => state?.prompts.prompts);
  const promptsStatus = useSelector(
    (state: RootState) => state?.prompts.status,
  );
  const { status: userValidationStatus, isValid } = useSelector(
    (state: { validUser }) => state?.validUser,
  );

  const accessToken = user?.accessToken || "";
  const user_id = user?.userId || "";

  const recommendedPrompt = useSelector(
    (state: { recommendedPrompts }) => state.recommendedPrompts.promptSelected,
  );

  // TODO reconsider state management approach
  const [messages, setMessages] = useState<Record<string, Partial<Message>[]>>(
    {},
  );
  const [invalidChat, setInvalidChat] = useState<boolean>(false);
  const [shareModal, setShareModal] = useState<boolean>(false);

  const isUserSignedIn = useMemo(() => {
    return !!auth?.currentUser;
  }, [auth?.currentUser]);

  const openShareModal = () => {
    setShareModal(true);
  };

  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);
  const [flow, setFlow] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isWatchlistDrawerOpen, removedIds, reorderIds } = useSelector(
    (state: { watchlist }) => state.watchlist,
  );

  const onClickWatchlistDisplayTrigger = useCallback(() => {
    if (isWatchlistDrawerOpen && (removedIds.length || reorderIds.length)) {
      setIsModalOpen(true);
    }
    else {
      dispatch(setWatchlistDrawerState(!isWatchlistDrawerOpen));
    }
  }, [removedIds, reorderIds, isWatchlistDrawerOpen]);

  const postMessages: PostMessagesFunction = useCallback((text, input_type, token, attachments) => {
    if (text.trim() || attachments?.images?.length) {
      const chat_id = uuidv4();
      const payload: FinchatMessagePayload = {
        input: text,
        chat_id,
        session_id: sessionIdSafe,
        user_id,
        accessToken: token ? token : accessToken,
        input_type,
      };
      if (attachments) {
        payload.attachments = attachments;
      }
      dispatch(
        finchatMessageLoad(payload),
      );

      setMessages((allMessages) => {
        const prevMessages = allMessages[sessionIdSafe] || [];

        return {
          ...allMessages,
          [sessionIdSafe]: [
            ...prevMessages,
            makeUserMessage(text, attachments),
          ],
        };
      });
    }
  },
  [sessionIdSafe, accessToken, finchatMessageLoad],
  );

  const setTempChatOnSidebar = (title, sessionId) => {
    if (!sessionId) return;
    dispatch(
      addMessageOptimistically({
        date: new Date().toISOString(),
        title,
        sessionId,
      }),
    );
  };

  const handleSendMessage: sendMessageFunction = (text, input_type, attachments) => {
    if (plan.promptsLeft === 0) {
      setIsLimitModalOpen(true);
      return;
    }
    // const decodedToken = jwtDecode(accessToken) as any;
    // const currentTime = Date.now() / 1000;
    // const auth = getAuth();
    // if (decodedToken.exp < currentTime) {
    //   getIdToken(auth.currentUser as any, true).then((token) => {
    //     dispatch(
    //       setUser({
    //         ...user,
    //         accessToken: token,
    //       })
    //     );
    //     postMessages(text, input_type, token);
    //   });
    // } else {
    postMessages(text, input_type, undefined, attachments);
    setTempChatOnSidebar(text, sessionIdSafe);
    // }
  };

  const handlePromptSelect = (prompt: string) => {
    if (!loading && isUserSignedIn) {
      logClickEvent("homepage_prompt_click", { clicked_prompt: prompt });
      handleSendMessage(prompt, "recommend");
      return;
    }
    setSignInModalOpen(true);
  };

  const handleCancel = () => {
    dispatch(finchatMessageCancel(session_id));
  };

  const handleNewChat = () => {
    handleCancel();

    const newSessionId = uuidv4();
    navigate(`/chat/${newSessionId}`);
    setTimeout(() => {
      setMessages((allMessages) => {
        return {
          ...allMessages,
          [newSessionId]: [],
        };
      });
    }, 300);
  };

  // Toggle drawer function
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    if (recommendedPrompt) {
      handleSendMessage(recommendedPrompt, "recommend");
      dispatch(clearPromptSelected());
    }
  }, [recommendedPrompt]);

  useEffect(() => {
    if (userValidationStatus === "succeeded" && !isValid) {
      navigate("/account-deleted");
    }
  }, [userValidationStatus, isValid, navigate]);

  useEffect(() => {
    if (pastMessages.length && !!user) {
      const formattedPastChat = transformArray(
        pastMessages,
        user,
        setInvalidChat,
      );
      setMessages((allMessages) => {
        return {
          ...allMessages,
          [sessionIdSafe]: formattedPastChat,
        };
      });
    }
  }, [pastMessages, user]);

  useEffect(() => {
    if (
      !prompts.length
      && promptsStatus === "idle"
      && tokenStatus === "succeeded"
    ) {
      dispatch(fetchPrompts());
    }
  }, [dispatch, prompts, promptsStatus, tokenStatus]);

  useEffect(() => {
    if (tokenStatus === "succeeded") {
      dispatch(fetchAllPlan());
    }
  }, [tokenStatus]);

  useEffect(() => {
    if (invalidChat) {
      // Not show error for now cause is showing it when user has changed.

      // dispatch(
      //   showNotification({
      //     message: `Unable to load conversation ${sessionIdSafe}`,
      //     severity: "error",
      //     horizontal: "right",
      //   })
      // );
      setInvalidChat(false);
      navigate(`/chat/${uuidv4()}`);
    }
  }, [invalidChat, dispatch, sessionIdSafe, navigate]);

  useEffect(() => {
    const handleMouseEnter = () => {
      const drawer = document.querySelector(".MuiDrawer-paperAnchorDockedLeft");
      if (drawer) {
        // @ts-ignore
        drawer.style.opacity = "0.6";
      }
    };

    const handleMouseLeave = () => {
      const drawer = document.querySelector(".MuiDrawer-paperAnchorDockedLeft");
      if (drawer) {
        // @ts-ignore
        drawer.style.opacity = "";
      }
    };

    const sidebarCarrot = sidebarCarrotRef.current;
    if (sidebarCarrot) {
      sidebarCarrot.addEventListener("mouseenter", handleMouseEnter);
      sidebarCarrot.addEventListener("mouseleave", handleMouseLeave);
    }
    return () => {
      if (sidebarCarrot) {
        sidebarCarrot.removeEventListener("mouseenter", handleMouseEnter);
        sidebarCarrot.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, []);

  useEffect(() => {
    if (finchatMessage?.output?.length && finchatMessage?.chat_id) {
      setMessages((allMessages) => {
        const prevMessages = allMessages[sessionIdSafe] || [];
        const existingMessageIndex = prevMessages.findIndex(
          m => m.chat_id === finchatMessage.chat_id,
        );
        // Update the existing bot response
        const sliceIndex
          = existingMessageIndex >= 0
            ? existingMessageIndex
            : prevMessages.length;
        const messageBefore = prevMessages.at(sliceIndex - 1);

        return {
          ...allMessages,
          [sessionIdSafe]: [
            ...prevMessages.slice(0, sliceIndex),
            ...(
              messageBefore?.isBot || finchatMessage.input !== messageBefore?.output
                ? [makeUserMessage(finchatMessage.input, finchatMessage.attachments)]
                : []
            ),
            {
              ...prevMessages[sliceIndex],
              outputLoading: finchatMessage.outputLoading,
              output: finchatMessage.output,
              isBot: true,
              chart_data: finchatMessage.chart_data, // Update with new chart data
              chart_flag: finchatMessage.chart_flag, // Update the chart flag
              chat_id: finchatMessage.chat_id,
              list_of_tickers: finchatMessage.list_of_tickers,
              list_of_recommendations: finchatMessage.list_of_recommendations,
            },
            ...prevMessages.slice(sliceIndex + 1),
          ],
        };
      });
    }
  }, [finchatMessage]);

  useEffect(() => {
    if (
      prompt
      && isUserSignedIn
      && planStatus === "succeeded"
      && allStatus === "succeeded"
    ) {
      // Check if there is a prompt and the user is signed in
      navigate(`/chat/${uuidv4()}`);
      handleSendMessage(decodeURIComponent(prompt), "user_type"); // Start a new chat session with the prompt, ensure to decode URI component
    }
  }, [prompt, isUserSignedIn, allStatus, planStatus, navigate]);

  // for user testing purposes only - let user override the market hours restriction
  const [isRestricted, setIsRestricted] = useState(false);
  const handleRestrictionChange = (event, newValue) => {
    if (newValue !== null) {
      localStorage.setItem("isRestricted", newValue);
      setIsRestricted(newValue);
    }
  };

  useEffect(() => {
    const storedValue = localStorage.getItem("isRestricted") === "true";
    if (storedValue !== isRestricted) {
      setIsRestricted(storedValue);
    }
  }, [isRestricted]);

  useEffect(() => {
    if (user && user.userId && tokenStatus === "succeeded") {
      dispatch(getTickersInWatchlist({ userId: user?.userId }));
    }
  }, [user, tokenStatus]);

  return (
    <div className="relative z-0 flex w-full overflow-hidden h-dvh">
      <Sidebar
        signInModalOpen={signInModalOpen}
        setSignInModalOpen={setSignInModalOpen}
        toggleDrawer={toggleDrawer}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        isTablet={isTablet}
        isDarkMode={isDarkMode}
        handleNewChat={handleNewChat}
        isMobile={isMobile}
        flow={flow}
        setFlow={setFlow}
        messages={messages[sessionIdSafe]}
        setMessages={setMessages}
      />
      <div className="relative flex h-full max-w-full flex-1 flex-col overflow-hidden">
        <main className="relative h-full w-full flex-1 overflow-auto transition-width">
          <Tooltip
            title={`${drawerOpen ? "Close" : "Open"} sidebar`}
            placement="right"
          >
            <div
              ref={sidebarCarrotRef}
              onClick={() => setDrawerOpen(prev => !prev)}
              className={`${
                isMobile ? "hidden" : "block"
              } fixed left-0 top-1/2 z-40 left-sidebar-carrot  ${
                !drawerOpen ? "-ml-[250px] closed" : ""
              }`}
            >
              <button>
                <span>
                  <div className="flex h-[88px] w-8 items-center justify-center sidebar-carrot-inner">
                    <div className="flex h-6 w-6 flex-col items-center">
                      <div className="h-3 w-1 rounded-full bg-black translator-a"></div>
                      <div className="h-3 w-1 rounded-full bg-black translator-b"></div>
                    </div>
                  </div>
                </span>
              </button>
            </div>
          </Tooltip>

          {showPerformanceDashboard && <PerformanceDashboard />}
          {showChangeLogs && <ChangeLogsPage />}
          {showDashboard && <Dashboard />}
          {!showDashboard && !showChangeLogs && !showPerformanceDashboard && (
            <>
              <div
                role="presentation"
                className="flex h-full flex-col chatmessagescrollbar"
              >
                <div className="flex-1 overflow-hidden">
                  <div className="relative h-full">
                    <div className="w-full h-full overflow-y-auto scroll-container">
                      <div
                        className={classNames("flex flex-col pb-9 text-sm", {
                          "h-full": !messages[sessionIdSafe]?.length,
                        })}
                      >
                        {/* flex h-full flex-col pb-9 text-sm */}
                        <header
                          className={`${
                            isMobile ? "justify-between" : "justify-start"
                          } px-6 py-1.5 md:pt-6 gap-5 sticky top-0 flex items-center z-10 font-semibold border-b border-token-border-medium md:border-transparent bg-token-surface-primary dark:bg-token-surface-secondary`}
                        >
                          {!!isMobile && (
                            <div className="toggle-drawer-button !items-start">
                              <IconButton
                                onClick={toggleDrawer}
                                className="!text-token-text-primary"
                              >
                                <MenuIcon />
                              </IconButton>
                            </div>
                          )}
                          {!isUserSignedIn && (
                            <Button
                              onClick={() => {
                                setFlow(2);
                                setSignInModalOpen(true);
                              }}
                              classes={{
                                root: `upgrade-plan-button !w-[90px]`,
                              }}
                            >
                              Sign Up
                            </Button>
                          )}
                          <div className="ml-auto">
                            {messages[sessionIdSafe]?.length > 0 && (
                              <Button
                                variant="outlined"
                                color="inherit"
                                size="medium"
                                className="button-outlined"
                                onClick={openShareModal}
                                sx={{
                                  minWidth: "36px",
                                  minHeight: "36px",
                                  padding: 0,
                                }}
                              >
                                <ShareStroked className="h-6 w-6" />
                              </Button>
                            )}
                          </div>
                          <div>
                            {!isWatchlistDrawerOpen
                            && (isMobile
                              ? (
                                  <IconButton
                                    size="small"
                                    className="cross-icon"
                                    onClick={() =>
                                      dispatch(setWatchlistDrawerState(true))}
                                  >
                                    <SignalStroke />
                                  </IconButton>
                                )
                              : (
                                  <Button
                                    variant="outlined"
                                    color="inherit"
                                    size="medium"
                                    className="button-outlined"
                                    onClick={() =>
                                      dispatch(setWatchlistDrawerState(true))}
                                  >
                                    <div className="flex gap-x-1">
                                      <SignalStroke />
                                      Watchlist
                                    </div>
                                  </Button>
                                ))}
                          </div>
                          {/* for testing purposes only - let testers override market hours restriction */}
                          <ToggleButtonGroup
                            value={isRestricted}
                            exclusive
                            onChange={handleRestrictionChange}
                            aria-label="restriction setting"
                            sx={{ backgroundColor: "white" }}
                            size="small"
                          >
                            <ToggleButton value={true}>
                              Market hour restriction
                            </ToggleButton>
                            <ToggleButton value={false}>
                              No restriction
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </header>
                        <section className="relative h-full w-full flex-1 overflow-auto transition-width">
                          <div className="flex h-full flex-col">
                            <div className="flex-1 overflow-hidde">
                              <div className="scroll-container h-full pt-2">
                                <ChatWindow
                                  sessionId={sessionIdSafe}
                                  isTablet={isTablet}
                                  isMobile={isMobile}
                                  messages={messages[sessionIdSafe]}
                                  setOpen={setSignInModalOpen}
                                  isUserSignedIn={isUserSignedIn}
                                  shareModal={shareModal}
                                  setShareModal={setShareModal}
                                />
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
                <MessageInput
                  sessionId={sessionIdSafe}
                  handleCancel={handleCancel}
                  setOpen={setSignInModalOpen}
                  handleSendMessage={handleSendMessage}
                  setMessages={setMessages}
                  setFlow={setFlow}
                  setSignInModalOpen={setSignInModalOpen}
                  showPrompts={!messages?.[sessionIdSafe]?.length}
                  handlePromptSelect={handlePromptSelect}
                />
              </div>
              <LimitModal
                open={isLimitModalOpen}
                setOpen={setIsLimitModalOpen}
                onUpgrade={() => navigate("/plans")}
                name={user?.name}
              />
            </>
          )}
        </main>
      </div>
      {/*  Watchlist is accessible only from chat page   */}
      {isChatPage && (
        <>
          <Tooltip
            title={`${isWatchlistDrawerOpen ? "Close" : "Open"} Watchlist`}
            placement="left"
          >
            <div
              onClick={onClickWatchlistDisplayTrigger}
              className={`${
                isMobile ? "hidden" : "block"
              } fixed right-0 top-1/2 z-40 right-sidebar-carrot  ${
                isWatchlistDrawerOpen ? "" : "closed"
              }`}
            >
              <button>
                <span>
                  <div className="flex h-[88px] w-8 items-center justify-center sidebar-carrot-inner">
                    <div className="flex h-6 w-6 flex-col items-center">
                      <div className="h-3 w-1 rounded-full bg-black translator-a"></div>
                      <div className="h-3 w-1 rounded-full bg-black translator-b"></div>
                    </div>
                  </div>
                </span>
              </button>
            </div>
          </Tooltip>
          <Watchlist
            isOpen={isWatchlistDrawerOpen}
            handlePrompt={text => postMessages(text, "recommend")}
          />
          <Modal isOpen={isModalOpen} onCloseModal={() => setIsModalOpen(false)} />
        </>
      )}
    </div>
  );
};

export default FinchatGPT;
