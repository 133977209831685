export const DocumentFilledIcon = ({ color = "currentColor", className = "", altClassName = "" }: { color?: string; className?: string; altClassName?: string }) => {
  return (
    <svg
      className={`${className}`}
      width="114"
      height="112"
      viewBox="0 0 114 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.55">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.3217 8.83325C71.6164 8.83325 71.899 8.95032 72.1074 9.15869L94.2826 31.3339C94.491 31.5423 94.608 31.8249 94.608 32.1196V91.0555C94.608 91.6691 94.1105 92.1666 93.4969 92.1666H30.5017C29.8881 92.1666 29.3906 91.6691 29.3906 91.0555V9.94436C29.3906 9.33071 29.8881 8.83325 30.5017 8.83325H71.3217ZM71.0573 31.2729C71.0573 31.8865 71.5548 32.384 72.1684 32.384H87.5777C88.5676 32.384 89.0634 31.1872 88.3634 30.4872L72.9541 15.0779C72.2541 14.3779 71.0573 14.8736 71.0573 15.8635V31.2729ZM73.9261 46.075C73.9261 48.2325 75.7268 49.9834 77.9507 49.9834C80.1663 49.9834 81.9753 48.2325 81.9753 46.075C81.9753 43.9175 80.1663 42.1666 77.9507 42.1666C75.7268 42.1666 73.9261 43.9175 73.9261 46.075ZM83.216 70.247C83.5937 70.7595 83.2278 71.4837 82.5912 71.4837H41.3964C40.7592 71.4837 40.3934 70.7583 40.7723 70.246L55.1294 50.8309C55.4405 50.4103 56.0702 50.4116 56.3795 50.8336L64.5788 62.0203C64.8898 62.4446 65.5239 62.4431 65.8329 62.0174L70.8247 55.1404C71.1332 54.7154 71.766 54.7131 72.0776 55.1358L83.216 70.247Z"
          fill={color}
        />
      </g>
      <path
        d="M63.2701 20.3217C62.9575 20.0091 62.5336 19.8335 62.0916 19.8335H22.0573C21.1368 19.8335 20.3906 20.5797 20.3906 21.5002V101.5C20.3906 102.421 21.1368 103.167 22.0573 103.167H83.9413C84.8618 103.167 85.608 102.421 85.608 101.5V43.3499C85.608 42.9079 85.4324 42.484 85.1199 42.1714L63.2701 20.3217Z"
        className={altClassName}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.3217 19.8333C62.6164 19.8333 62.899 19.9503 63.1074 20.1587L85.2826 42.3339C85.491 42.5423 85.608 42.8249 85.608 43.1196V102.055C85.608 102.669 85.1105 103.167 84.4969 103.167H21.5017C20.8881 103.167 20.3906 102.669 20.3906 102.055V20.9444C20.3906 20.3307 20.8881 19.8333 21.5017 19.8333H62.3217ZM62.0573 42.2729C62.0573 42.8865 62.5548 43.384 63.1684 43.384H78.5777C79.5676 43.384 80.0634 42.1872 79.3634 41.4872L63.9541 26.0779C63.2541 25.3779 62.0573 25.8736 62.0573 26.8635V42.2729ZM64.9261 57.075C64.9261 59.2325 66.7268 60.9834 68.9507 60.9834C71.1663 60.9834 72.9753 59.2325 72.9753 57.075C72.9753 54.9175 71.1663 53.1666 68.9507 53.1666C66.7268 53.1666 64.9261 54.9175 64.9261 57.075ZM74.216 81.247C74.5937 81.7595 74.2278 82.4837 73.5912 82.4837H32.3964C31.7592 82.4837 31.3934 81.7583 31.7723 81.246L46.1294 61.8309C46.4405 61.4103 47.0702 61.4116 47.3795 61.8336L55.5788 73.0203C55.8898 73.4446 56.5239 73.4431 56.8329 73.0174L61.8247 66.1404C62.1332 65.7154 62.766 65.7131 63.0776 66.1358L74.216 81.247Z"
        fill={color}
      />
    </svg>
  );
};
