import { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { showNotification } from "../redux/slices/notificationSlice";

interface ImageType {
  src?: string;
  fileId: string;
  url: string;
  isLoading: boolean;
}
const useUploadImages = () => {
  const [images, setImages] = useState<ImageType[]>([]);
  const dispatch = useDispatch();

  const isImageLoading = useMemo(() => images.some(image => image.isLoading), [images]);

  const addImage = (image: ImageType) => {
    setImages(prev => prev.concat(image));
  };

  const updateImageSrc = (fileId: string, src: string) => {
    setImages(prevImages => prevImages.map((image) => {
      return fileId === image.fileId ? { ...image, src, isLoading: false } : image;
    }));
  };

  const uploadImageFiles = (files: File[]) => {
    files.forEach((file) => {
      if (file.type.startsWith("image/")) {
        uploadImage(file);
      }
    });
  };

  const uploadImage = async (file: File) => {
    let fileId;
    try {
      const payload = {
        file_name: file.name,
      };
      const { data } = await axios.post(`${process.env.REACT_APP_SPL_URL}/v1/tradegpt/chats/files`, payload, { withCredentials: true });
      const url = URL.createObjectURL(file);
      fileId = data.file_id;
      addImage({
        isLoading: true,
        fileId,
        url,
      });
      const response = await axios.put(data.upload_url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
      if (response.status === 200 || response.status === 204) {
        const { data: imageData } = await axios.put(`${process.env.REACT_APP_SPL_URL}/v1/tradegpt/chats/files/${data.file_id}/upload`, {
          status: "uploaded",
        }, { withCredentials: true });
        updateImageSrc(imageData.file_id, imageData.download_url);
      }
      else {
        removeImage(fileId);
        dispatch(showNotification({ title: "error uploading image", message: "error uploading image", severity: "error", horizontal: "center" }));
      }
    }
    catch {
      deleteImage(fileId);
      dispatch(showNotification({ title: "error uploading image", message: "error uploading image", severity: "error", horizontal: "center" }));
    }
  };

  const removeImage = (fileId: string) => {
    const newImages = images.filter(item => item.fileId !== fileId);
    setImages(newImages);
  };

  const deleteImage = (fileId: string) => {
    try {
      removeImage(fileId);
      axios.delete(`${process.env.REACT_APP_SPL_URL}/v1/tradegpt/chats/files/${fileId}`, { withCredentials: true });
    }
    catch {
      console.error("Delete image failed");
    }
  };

  const clearImages = () => {
    if (images.length) {
      images.forEach((image) => {
        URL.revokeObjectURL(image.url);
      });
    }
    setImages([]);
  };

  return {
    images,
    isImageLoading,
    deleteImage,
    clearImages,
    uploadImageFiles,
  };
};

export default useUploadImages;
