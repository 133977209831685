import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import TradeGPT from "./components/TradeGPT";
import { v4 as uuidv4 } from "uuid";
import Faq from "./components/Faq";
import SharedChat from "./components/Share/SharedChat";
import ThankYou from "./components/ThankYou";
import PlansWrapper from "./components/Plans/PlansWrapper";
import TermsAndPolicies from "./components/terms_and_policies/TermsAndPolicies";
import TermsOfService from "./components/terms_and_policies/TermsOfService";
import PrivacyPolicy from "./components/terms_and_policies/PrivacyPolicy";
import LegalDisclaimer from "./components/terms_and_policies/LegalDisclaimer";
import NotFoundPage from "./components/404page";
import Session from "./components/Session/Session";
import SignIn from "./components/SignInP";
import SignUp from "./components/SignUpP";
import AppWrapper from "./components/AppWrapper";
import DeletedAccountPage from "./components/DeletedAccountPage";
import LocationListener from "./components/LocationListener";

import ConfidentialsWrapper from "./components/ConfidentialsWrapper";
import AuthRoutes from "./components/AuthRoute";
import WebsocketWrapper from "./components/Sidebar/WebsocketWrapper";

const AppRoutes = () => {
  return (
    <Router>
      <AppWrapper>
        <ConfidentialsWrapper>
          <WebsocketWrapper>
            <Routes>
              <Route element={<LocationListener />}>
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signin" element={<SignIn />} />
                <Route
                  path="/"
                  element={
                    <AuthRoutes />
                  }
                />
                <Route path="/dashboard" element={<TradeGPT showDashboard />} />
                <Route path="/performance-dashboard" element={<TradeGPT showPerformanceDashboard />} />
                <Route path="/plans" element={<PlansWrapper />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/chat" element={<Navigate to={`/chat/${uuidv4()}`} />} />
                <Route path="/chat/:session_id" element={<TradeGPT key={uuidv4()} />} />
                <Route path="/share/:session_id/:title?" element={<SharedChat />} />
                <Route path="/thank-you" element={<ThankYou />} />
                <Route path="/policies" element={<TermsAndPolicies />} />
                <Route path="/policies/service-terms" element={<TermsOfService />} />
                <Route path="/policies/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/session" element={<Session />} />
                <Route path="/prompt/:prompt" element={<TradeGPT />} />
                <Route path="/account-deleted" element={<DeletedAccountPage />} />
                <Route path="/release-notes" element={<TradeGPT showChangeLogs />} />
                <Route path="*" element={<NotFoundPage />} />
                <Route
                  path="/policies/legal-disclaimer"
                  element={<LegalDisclaimer />}
                />
              </Route>
            </Routes>
          </WebsocketWrapper>
        </ConfidentialsWrapper>
      </AppWrapper>
    </Router>
  );
};
// </>

export default AppRoutes;
