// src/slices/notificationSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationState } from "../../assets/interfaces/interfaces";

const initialState: NotificationState = {
  open: false,
  message: "",
  severity: "success",
  horizontal: "right",
  title: "",
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showNotification: (state, action: PayloadAction<Omit<NotificationState, "open">>) => {
      state.open = true;
      state.message = action.payload.message;
      state.title = action.payload.title || "";
      state.severity = action.payload.severity;
      state.horizontal = action.payload.horizontal;
    },
    hideNotification: (state) => {
      state.open = false;
      state.title = "";
    },
  },
});

export const { showNotification, hideNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
