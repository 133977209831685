import { CircularProgress, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Tooltip, Typography } from "@mui/material";
import { useMemo, useState, useRef, useEffect, useCallback, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorCode, useDropzone } from "react-dropzone";
import { MessageInputProps, RootState } from "../assets/interfaces/interfaces";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { DocumentAddIcon } from "./Icons/IconsDocumentAdd";
import { DocumentFilledIcon } from "./Icons/IconsDocumentFilled";
import SuggestionPrompts from "./SuggestionPrompts";
import StopIcon from "@mui/icons-material/Stop";
import { getAuth } from "firebase/auth";
import useUploadImages from "../hooks/useUploadImages";
import { showNotification } from "../redux/slices/notificationSlice";

const MAX_UPLOAD_FILES = 5;
const MAX_IMAGE_FILE_SIZE = 1024 * 1024 * 20;
const MAX_IMAGE_FILE_SIZE_IN_MB = "20MB";
const ACCEPTED_IMAGE_TYPE = {
  "image/jpeg": [".jpeg", ".jpg"],
  "image/webp": [],
  "image/png": [".png"],
  "image/gif": [".gif"],
};
const FILE_UPLOAD_ERROR_MAPPING = {
  [ErrorCode.FileInvalidType]: {
    title: "Invalid File Format",
    message: "The file format is not supported. Please upload files in JPG, JPEG, PNG or WEBP format only.",
  },
  [ErrorCode.FileTooLarge]: {
    title: "Invalid File Size",
    message: `The file you have selected is too large. Please ensure your file is ${MAX_IMAGE_FILE_SIZE_IN_MB} or smaller before uploading.`,
  },
  [ErrorCode.TooManyFiles]: {
    title: "Upload Limit Reached",
    message: "You’ve exceeded the maximum image upload for a single message. To upload a new file, please delete an existing upload.",
  },
};

const MessageInput: FC<MessageInputProps> = ({
  sessionId,
  handleSendMessage,
  setOpen,
  setMessages,
  setFlow,
  setSignInModalOpen,
  handleCancel,
  showPrompts,
  handlePromptSelect,
}) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [hoveredImage, setHoveredImage] = useState(null);
  const [attachMenuOpen, setAttachMenuOpen] = useState(false);
  const messageTextArea = useRef<HTMLTextAreaElement>(null);
  const messageButton = useRef<HTMLButtonElement>(null);
  const attachButton = useRef<HTMLButtonElement>(null);
  const fileUploadInput = useRef<HTMLInputElement>(null);
  const { images, isImageLoading, uploadImageFiles, deleteImage, clearImages } = useUploadImages();

  const [isDragging, setIsDragging] = useState(false);
  const dragCounter = useRef(0);

  const handleDrag = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);
  const handleDragIn = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounter.current++;
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  }, []);
  const handleDragOut = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current > 0) return;
    setIsDragging(false);
  }, []);
  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  }, []);

  useEffect(() => {
    window.addEventListener("dragenter", handleDragIn);
    window.addEventListener("dragleave", handleDragOut);
    window.addEventListener("dragover", handleDrag);
    window.addEventListener("drop", handleDrop);
    return function cleanUp() {
      window.removeEventListener("dragenter", handleDragIn);
      window.removeEventListener("dragleave", handleDragOut);
      window.removeEventListener("dragover", handleDrag);
      window.removeEventListener("drop", handleDrop);
      if (images.length) {
        images.forEach((image) => {
          URL.revokeObjectURL(image.url);
        });
      }
    };
  });

  const onDropAccepted = useCallback((files) => {
    uploadImageFiles(files);
  }, []);

  const onDropRejected = useCallback((files) => {
    for (const file of files) {
      const error = file.errors[0];
      dispatch(showNotification({ ...FILE_UPLOAD_ERROR_MAPPING[error.code], severity: "error", horizontal: "center" }));
      if (error.code === ErrorCode.TooManyFiles) {
        return;
      }
    }
  }, [dispatch]);

  const handleFiles = useCallback((files) => {
    if (files.length > MAX_UPLOAD_FILES - images.length) {
      const fileErrorObj = Array.from(files).map((file) => {
        return { file, errors: [{ message: FILE_UPLOAD_ERROR_MAPPING[ErrorCode.TooManyFiles], code: ErrorCode.TooManyFiles }] };
      });
      onDropRejected(fileErrorObj);
    }
    else {
      const accepted = [];
      const rejected = [];
      for (const file of files) {
        if (file.size > MAX_IMAGE_FILE_SIZE) {
          rejected.push({ file, errors: [{ message: FILE_UPLOAD_ERROR_MAPPING[ErrorCode.FileTooLarge], code: ErrorCode.FileTooLarge }] });
        }
        else if (!Object.keys(ACCEPTED_IMAGE_TYPE).some(type => file.type === type)) {
          rejected.push({ file, errors: [{ message: FILE_UPLOAD_ERROR_MAPPING[ErrorCode.FileInvalidType], code: ErrorCode.FileInvalidType }] });
        }
        else {
          accepted.push(file);
        }
      }
      if (accepted.length) {
        onDropAccepted(accepted);
      }
      if (rejected.length) {
        onDropRejected(rejected);
      }
    }
  }, [images]);

  const handleFileInput = useCallback((event) => {
    const files = event.target.files;
    handleFiles(files);
  }, [handleFiles]);

  const handlePaste = useCallback((event) => {
    const item = event.clipboardData.items[0];
    if (item.kind === "file") {
      event.preventDefault();
      const files = Array.from(event.clipboardData.files);
      handleFiles(files);
    }
  }, [handleFiles]);

  const maxFiles = useMemo(() => Math.max(MAX_UPLOAD_FILES - images.length, 0), [images]);

  const { isDragActive, getRootProps } = useDropzone({
    maxFiles,
    maxSize: MAX_IMAGE_FILE_SIZE,
    accept: ACCEPTED_IMAGE_TYPE,
    onDropAccepted,
    onDropRejected,
    validator: () => {
      if (maxFiles <= 0) {
        return { message: ErrorCode.TooManyFiles, code: ErrorCode.TooManyFiles };
      }
    },
  });

  const finchatMessage = useSelector(
    (state: RootState) => state?.finchat?.finchatMessages?.[sessionId],
  );
  const auth = getAuth();
  const isUserSignedIn = useMemo(() => {
    return !!auth?.currentUser;
  }, [auth?.currentUser]);

  const finchatmessageLoading = finchatMessage?.outputLoading;

  const handleSend = () => {
    if (!finchatmessageLoading && isUserSignedIn) {
      if (images.length) {
        handleSendMessage(inputValue, "user_type", { images: images.map(image => ({ image_url: image.src, file_id: image.fileId })) });
      }
      else {
        handleSendMessage(inputValue, "user_type");
      }
      setInputValue("");
      clearImages();
    }
    else {
      if (!finchatMessage?.outputLoading) setOpen(true);
    }
  };

  const handleAutoHeight = () => {
    if (messageTextArea.current) {
      messageTextArea.current.style.height = "auto"; // Reset height to auto to calculate new height

      const computedStyle = window.getComputedStyle(messageTextArea.current);
      const maxHeightValue = computedStyle.getPropertyValue("max-height");
      const numericMaxHeight = parseFloat(maxHeightValue.replace("px", ""));

      const textareaHeight = messageTextArea.current.scrollHeight;

      messageTextArea.current.style.height = `${Math.min(
        textareaHeight,
        numericMaxHeight,
      )}px`;
      if (textareaHeight >= numericMaxHeight) {
        messageTextArea.current.style.overflowY = "auto";
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default action (new line) when Enter is pressed without Shift
      if (!finchatmessageLoading && isUserSignedIn) {
        if (images.length) {
          handleSendMessage(inputValue, "user_type", { images: images.map(image => ({ image_url: image.src, file_id: image.fileId })) });
        }
        else {
          handleSendMessage(inputValue, "user_type");
        }
        setInputValue("");
        clearImages();
        if (messageTextArea.current) {
          messageTextArea.current.style.height = "auto";
        }
      }
      else {
        if (!isUserSignedIn) {
          setMessages({});
          setFlow(0);
          setSignInModalOpen(true);
        }
      }
    }
  };

  useEffect(() => {
    if (inputValue === "" && messageTextArea.current) {
      messageTextArea.current.style.height = "auto"; // Reset height to auto to ensure it shrinks back
    }
  }, [inputValue]);

  const { status } = useSelector((state: { plan }) => state.plan);
  const authLoading = useSelector((state: { auth }) => state.auth.loading);
  const disableSend = (!inputValue.trim().length && !images.length) || isImageLoading || authLoading || status !== "succeeded" || finchatmessageLoading;
  const disableAttach = authLoading || status !== "succeeded" || finchatmessageLoading;
  return (
    <div className="w-full pt-2 md:pt-0 dark:border-white/20 md:border-transparent md:dark:border-transparent md:w-[calc(100%-.5rem)]">
      <form className="stretch mx-2 flex flex-row gap-3 last:mb-2 md:mx-4 md:last:mb-6 lg:mx-auto lg:max-w-2xl xl:max-w-3xl">
        <div className="relative flex h-full flex-1 items-stretch md:flex-col">
          {showPrompts && (
            <SuggestionPrompts onPromptSelect={handlePromptSelect} />
          )}
          <div className="flex w-full items-center">
            <div className="overflow-hidden [&:has(textarea:focus)]:border-token-border-xheavy [&:has(textarea:focus)]:shadow-[0_2px_6px_rgba(0,0,0,.05)] py-2 flex flex-col w-full dark:border-token-border-heavy flex-grow relative border border-token-border-heavy dark:text-white rounded-2xl bg-white dark:bg-gray-800 shadow-[0_0_0_2px_rgba(255,255,255,0.95)] dark:shadow-[0_0_0_2px_rgba(52,53,65,0.95)]">
              <div className="flex px-10 md:px-12">
                {
                  images.map((image, index) => (
                    <div
                      onMouseEnter={() => setHoveredImage(index)}
                      onMouseLeave={() => setHoveredImage(null)}
                      key={image.url}
                      className="relative pr-2 pt-2"
                    >
                      {
                        hoveredImage === index
                        && (
                          <div className="absolute top-0 right-0">
                            <IconButton
                              onClick={() => deleteImage(image.fileId)}
                              className="rounded-full !bg-mui-black-56 dark:!bg-mui-white-56 !p-0.5 z-10"
                              disabled={image.isLoading}
                            >
                              <CloseIcon className="!w-4 !h-4 dark:text-black text-white" />
                            </IconButton>
                          </div>
                        )
                      }
                      <div className="flex relative justify-center items-center w-full h-full rounded-lg border-[0.75px] border-elevation-outline dark:border-mui-white-12 overflow-hidden">
                        {
                          image.isLoading
                          && (
                            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                              <CircularProgress size={16} disableShrink color="inherit" className="text-tgpt-secondary-main" />
                            </div>
                          )
                        }
                        <img
                          src={image.url}
                          alt="uploaded image"
                          className="w-12 h-12 object-cover"
                        />
                      </div>
                    </div>
                  ))
                }
              </div>
              <div className="flex gap-2 items-end px-2 md:px-3 py-2">
                <IconButton
                  ref={attachButton}
                  disabled={disableAttach}
                  onClick={() => setAttachMenuOpen(true)}
                  className="w-[30px] h-[30px] disabled:opacity-10 disabled:text-gray-400 !p-1"
                >
                  <Tooltip title="Attach File">
                    <AttachFileIcon className="!w-5 !h-5 dark:text-white text-black" />
                  </Tooltip>
                </IconButton>
                <Popper
                  open={attachMenuOpen}
                  anchorEl={attachButton.current}
                  role={undefined}
                  placement="top-start"
                  transition
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start" ? "left top" : "left bottom",
                      }}
                    >
                      <Paper className="dark:bg-white">
                        <ClickAwayListener onClickAway={() => setAttachMenuOpen(false)}>
                          <MenuList
                            autoFocusItem={attachMenuOpen}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                          >
                            <MenuItem
                              className="gap-3"
                              onClick={() => {
                                fileUploadInput.current.click();
                                setAttachMenuOpen(false);
                              }}
                            >
                              <DocumentAddIcon />
                              Upload from computer
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                <input
                  ref={fileUploadInput}
                  accept="image/gif, image/png, image/jpeg image/webp"
                  type="file"
                  onChange={handleFileInput}
                  className="hidden"
                  multiple
                />
                <textarea
                  ref={messageTextArea}
                  value={inputValue}
                  rows={1}
                  onChange={(e) => {
                    handleAutoHeight();
                    setInputValue(e.target.value);
                  }}
                  onKeyPress={handleKeyPress}
                  tabIndex={0}
                  placeholder="Type your message…"
                  className="m-0 py-1 w-full leading-5 resize-none border-0 bg-transparent focus:ring-0 focus-visible:ring-0 focus-visible:ring-transparent dark:bg-transparent placeholder-black/50 dark:placeholder-white/50 scroll-container max-h-52"
                  onPaste={handlePaste}
                >
                </textarea>
                {finchatMessage?.outputLoading
                  ? (
                      <div className="p-[3px]">
                        <div className="flex h-full flex-row items-center justify-center">
                          <button
                            type="button"
                            onClick={() => handleCancel()}
                            className="rounded-full border-2 border-gray-950 p-0.5 dark:border-gray-200 leading-0"
                            aria-label="Stop generating"
                          >
                            <StopIcon className="!w-4 !h-4" />
                          </button>
                        </div>
                      </div>
                    )
                  : (
                      <button
                        ref={messageButton}
                        type="button"
                        disabled={disableSend}
                        onClick={handleSend}
                        className="dark:hover:bg-white w-[30px] h-[30px] bg-black disabled:opacity-10 disabled:text-gray-400 text-white p-0.5 border border-black rounded-lg dark:border-white dark:bg-white transition-colors leading-none"
                      >
                        <Tooltip title="Send Message">
                          <ArrowUpwardIcon className="!w-6 !h-6 text-white dark:text-black" />
                        </Tooltip>
                      </button>
                    )}
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="relative px-2 py-2 text-center text-xs text-token-text-secondary md:px-[60px]">
        <span>
          TradeGPT is for informational purposes only. Consult a professional
          before making investment decisions.
        </span>
      </div>
      <div {...getRootProps({ className: `absolute flex items-center justify-center backdrop-blur-[8px] top-0 left-0 w-full h-full z-10 ${isDragging ? "visible" : "invisible"}` })}>
        {
          isDragActive
          && (
            <div className="flex flex-col gap-1 items-center text-mui-black-87 dark:text-white ">
              <DocumentFilledIcon className="text-[#58585D] dark:text-mui-light-gray" altClassName="fill-white dark:fill-[#2C2C2C]" />
              <Typography variant="h6/medium-500">Add anything</Typography>
              <Typography variant="body2/regular-400">
                Drop any file here to add it to the conversation
              </Typography>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default MessageInput;
