export const DocumentAddIcon = ({ color = "currentColor", className = "" }: { color?: string; className?: string }) => {
  return (
    <svg
      className={`${className}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.35 18.65V3.35H11.325V6.85598C11.325 7.42759 11.7884 7.89098 12.36 7.89098H15.8659V9.50136C15.9105 9.50045 15.9552 9.5 16 9.5C16.4156 9.5 16.822 9.539 17.2159 9.61353V7.36503C17.2159 7.09053 17.1069 6.82727 16.9128 6.63317L12.5827 2.30314C12.3886 2.10904 12.1254 2 11.8509 2H4.035C3.46339 2 3 2.46338 3 3.035V18.965C3 19.5366 3.46339 20 4.035 20H10.8762C10.5534 19.5871 10.2794 19.1342 10.063 18.65H4.35ZM14.9114 6.54098L12.675 4.30459V6.54098H14.9114ZM16 11.75C13.6528 11.75 11.75 13.6528 11.75 16C11.75 18.3472 13.6528 20.25 16 20.25C18.3472 20.25 20.25 18.3472 20.25 16C20.25 13.6528 18.3472 11.75 16 11.75ZM10.25 16C10.25 12.8244 12.8244 10.25 16 10.25C19.1756 10.25 21.75 12.8244 21.75 16C21.75 19.1756 19.1756 21.75 16 21.75C12.8244 21.75 10.25 19.1756 10.25 16ZM16.75 14C16.75 13.5858 16.4142 13.25 16 13.25C15.5858 13.25 15.25 13.5858 15.25 14V15.25H14C13.5858 15.25 13.25 15.5858 13.25 16C13.25 16.4142 13.5858 16.75 14 16.75H15.25V18C15.25 18.4142 15.5858 18.75 16 18.75C16.4142 18.75 16.75 18.4142 16.75 18V16.75H18C18.4142 16.75 18.75 16.4142 18.75 16C18.75 15.5858 18.4142 15.25 18 15.25H16.75V14Z"
        fill={color}
        fillOpacity="0.56"
      />
    </svg>
  );
};
